@if (targetedSubscribers$ | async) {
  <div class="mt-6 mb-3">
    <div class="flex justify-between items-end mb-1">
      <div class="font-bold">
        {{ 'notification-targeted-subscribers.total' | translate }}
      </div>
      <div>
        <span class="text-brand font-bold text-xl">
          {{
            (targetedSubscribers$ | async).ios + (targetedSubscribers$ | async).android
          }}</span
        >
        {{ 'notification-targeted-subscribers.people' | translate }}
      </div>
    </div>
    <p>
      <span class="font-bold"> {{ (targetedSubscribers$ | async).ios }}</span>
      <span class="text-[#676767]">
        {{ 'notification-targeted-subscribers.ios' | translate }}
      </span>
      <!-- <fa-icon class="opacity-70" [icon]="['fab', 'apple']"></fa-icon> -->
    </p>
    <p>
      <span class="font-bold"> {{ (targetedSubscribers$ | async).android }}</span>
      <span class="text-[#676767]">
        {{ 'notification-targeted-subscribers.android' | translate }}
      </span>

      <!-- <fa-icon class="opacity-70" [icon]="['fab', 'android']"></fa-icon> -->
    </p>
  </div>
}
