import { Injectable } from '@angular/core';
import { getAppId$ } from '@app/core/utils';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, mergeMap } from 'rxjs';
import {
  Notification,
  NotificationApi,
  transformNotificationApi,
} from '../models/notification.model';
import { NotificationsService } from '../services/notifications.service';
import {
  CloseNotificationForm,
  DecrementPage,
  DeleteNotificationError,
  DeleteNotificationRequest,
  DeleteNotificationSuccess,
  FetchNotificationsError,
  FetchNotificationsRequest,
  FetchNotificationsSuccess,
  FetchTargetedSubscribersError,
  FetchTargetedSubscribersRequest,
  FetchTargetedSubscribersSuccess,
  IncrementPage,
  OpenEditNotificationForm,
  OpenNotificationForm,
  OpenNotificationFormFromSidebar,
  ResetCurrentNotification,
  ResetPagination,
  ScheduleNotificationSuccess,
  SetPage,
  SubmitDraftSuccess,
  SubmitNotificationError,
  SubmitNotificationFrequencyError,
  SubmitNotificationQuotaError,
  SubmitNotificationRequest,
  SubmitNotificationSuccess,
  UpdateNotificationError,
  UpdateNotificationSuccess,
} from './notifications.actions';

export interface TargetedSubscribers {
  ios: number;
  android: number;
}

export class NotificationStateModel {
  notifications: Notification[];
  isLoading: boolean;
  isFormVisible: boolean;
  isOpenFormButtonVisible: boolean;
  isSubmitting: boolean;
  currentNotification: Notification;
  currentPage: number;
  lastPage: number;
  totalNumber: number;
  targetedSubscribers: TargetedSubscribers;
}

@State<NotificationStateModel>({
  name: 'notifications',
  defaults: {
    notifications: [],
    isLoading: false,
    isFormVisible: false,
    isOpenFormButtonVisible: true,
    isSubmitting: false,
    currentNotification: null,
    currentPage: 1,
    lastPage: null,
    totalNumber: null,
    targetedSubscribers: {
      ios: 0,
      android: 0,
    },
  },
})
@Injectable()
export class NotificationsState {
  constructor(
    private readonly notificationsService: NotificationsService,
    private readonly store: Store,
  ) {}

  @Selector()
  static isLoading(state: NotificationStateModel): boolean {
    return state.isLoading;
  }

  @Selector()
  static notifications(state: NotificationStateModel): Notification[] {
    return state.notifications;
  }

  @Selector()
  static isFormVisible(state: NotificationStateModel): boolean {
    return state.isFormVisible;
  }

  @Selector()
  static isOpenFormButtonVisible(state: NotificationStateModel): boolean {
    return state.isOpenFormButtonVisible;
  }

  @Selector()
  static isSubmitting(state: NotificationStateModel): boolean {
    return state.isSubmitting;
  }

  @Selector()
  static currentNotification(state: NotificationStateModel): Notification {
    return state.currentNotification;
  }

  @Selector()
  static currentPage(state: NotificationStateModel): number {
    return state.currentPage;
  }

  @Selector()
  static lastPage(state: NotificationStateModel): number {
    return state.lastPage;
  }

  @Selector()
  static totalNumber(state: NotificationStateModel): number {
    return state.totalNumber;
  }

  @Selector()
  static targetedSubscribers(state: NotificationStateModel): TargetedSubscribers {
    return state.targetedSubscribers;
  }

  @Selector()
  static hasTargetedSubscribers(state: NotificationStateModel): boolean {
    return state.targetedSubscribers.ios + state.targetedSubscribers.android > 0;
  }

  @Action(FetchNotificationsRequest)
  fetchNotificationsRequest(ctx: StateContext<NotificationStateModel>) {
    ctx.patchState({
      isLoading: true,
    });

    const currentPage = ctx.getState().currentPage;

    return getAppId$(this.store).pipe(
      mergeMap(appId => this.notificationsService.getNotifications(appId, currentPage)),
      mergeMap(notificationsApiResponse =>
        ctx.dispatch(new FetchNotificationsSuccess(notificationsApiResponse)),
      ),
      catchError(err => ctx.dispatch(new FetchNotificationsError(err))),
    );
  }

  @Action(FetchNotificationsSuccess)
  fetchNotificationsSuccess(
    ctx: StateContext<NotificationStateModel>,
    { notificationsApiResponse }: FetchNotificationsSuccess,
  ) {
    const notifications = notificationsApiResponse.data.map(
      (notification: NotificationApi) => transformNotificationApi(notification),
    );
    ctx.patchState({
      notifications,
      isLoading: false,
      lastPage: notificationsApiResponse.last_page,
      totalNumber: notificationsApiResponse.total,
    });
  }

  @Action(FetchNotificationsError)
  fetchNotificationsError(ctx: StateContext<NotificationStateModel>) {
    ctx.patchState({
      isLoading: false,
    });
  }

  @Action(OpenNotificationForm)
  openNotificationForm(ctx: StateContext<NotificationStateModel>) {
    ctx.patchState({
      isFormVisible: true,
    });
    setTimeout(() => {
      ctx.patchState({
        isOpenFormButtonVisible: false,
      });
    }, 500);
  }

  @Action(OpenNotificationFormFromSidebar)
  openNotificationFormFromSidebar(ctx: StateContext<NotificationStateModel>) {
    ctx.patchState({
      isOpenFormButtonVisible: false,
    });
    setTimeout(() => {
      ctx.patchState({
        isFormVisible: true,
      });
    }, 500);
  }

  @Action(CloseNotificationForm)
  closeNotificationForm(ctx: StateContext<NotificationStateModel>) {
    ctx.patchState({
      isFormVisible: false,
    });
    setTimeout(() => {
      ctx.patchState({
        isOpenFormButtonVisible: true,
      });
    }, 500);
  }

  @Action(SubmitNotificationRequest)
  submitNotificationRequest(
    ctx: StateContext<NotificationStateModel>,
    { notification }: SubmitNotificationRequest,
  ) {
    const currentNotification = ctx.getState().currentNotification;

    ctx.patchState({
      isSubmitting: true,
    });

    // testing if it is an existing notification
    if (currentNotification?.id) {
      // testing if the  existing notification has to be sent immediately..
      if (!notification.sendAt && notification.enabled) {
        return getAppId$(this.store).pipe(
          mergeMap(appId => {
            notification.sendAt = new Date();

            return this.notificationsService.updateNotification(
              appId,
              currentNotification.id,
              notification,
            );
          }),
          mergeMap(() => ctx.dispatch(new SubmitNotificationSuccess())),
          catchError(() => ctx.dispatch(new SubmitNotificationError())),
        );

        // ...or updated to be sent later
      } else {
        return getAppId$(this.store).pipe(
          mergeMap(appId =>
            this.notificationsService.updateNotification(
              appId,
              currentNotification.id,
              notification,
            ),
          ),
          mergeMap(() => ctx.dispatch(new UpdateNotificationSuccess())),
          catchError(error => ctx.dispatch(new UpdateNotificationError(error))),
        );
      }
    }

    // if it isn't an existing notification...
    return getAppId$(this.store).pipe(
      mergeMap(appId => this.notificationsService.sendNotification(notification, appId)),
      mergeMap(sentNotification => {
        // ...it is either scheduled
        if (notification.sendAt) {
          return ctx.dispatch(new ScheduleNotificationSuccess());
          // ...saved as a draft
        } else if (!sentNotification.enabled) {
          return ctx.dispatch(new SubmitDraftSuccess());
          // ...or sent immediately
        } else {
          return ctx.dispatch(new SubmitNotificationSuccess());
        }
      }),
      catchError(error => {
        if (error.error.errors[0] === 'exceeded_quota') {
          return ctx.dispatch(new SubmitNotificationQuotaError());
        } else if (error.error.errors[0] === 'delay_exception') {
          return ctx.dispatch(new SubmitNotificationFrequencyError());
        }

        return ctx.dispatch(new SubmitNotificationError());
      }),
    );
  }

  @Action(SubmitNotificationSuccess)
  submitNotificationSuccess(ctx: StateContext<NotificationStateModel>) {
    ctx.patchState({
      isSubmitting: false,
      currentNotification: null,
    });
    ctx.dispatch(new FetchNotificationsRequest(1));
  }

  @Action(SubmitNotificationError)
  submitNotificationError(ctx: StateContext<NotificationStateModel>) {
    ctx.patchState({
      isSubmitting: false,
    });
  }

  @Action(SubmitNotificationFrequencyError)
  submitNotificationFrequencyError(ctx: StateContext<NotificationStateModel>) {
    ctx.patchState({
      isSubmitting: false,
    });
  }

  @Action(SubmitNotificationQuotaError)
  submitNotificationQuotaError(ctx: StateContext<NotificationStateModel>) {
    ctx.patchState({
      isSubmitting: false,
    });
  }

  @Action(OpenEditNotificationForm)
  openEditForm(
    ctx: StateContext<NotificationStateModel>,
    { notification }: OpenEditNotificationForm,
  ) {
    ctx.patchState({
      isFormVisible: true,
      currentNotification: notification,
    });
  }

  @Action(ResetCurrentNotification)
  resetCurrentNotification(ctx: StateContext<NotificationStateModel>) {
    ctx.patchState({ currentNotification: null });
  }

  @Action(UpdateNotificationSuccess)
  updateNotificationSuccess(ctx: StateContext<NotificationStateModel>) {
    ctx.patchState({
      isSubmitting: false,
      currentNotification: null,
    });
    ctx.dispatch(new FetchNotificationsRequest(1));
  }

  @Action(UpdateNotificationError)
  updateNotificationError(ctx: StateContext<NotificationStateModel>) {
    ctx.patchState({
      isSubmitting: false,
    });
  }

  @Action(DeleteNotificationRequest)
  deleteNotificationRequest(
    ctx: StateContext<NotificationStateModel>,
    { notificationId }: DeleteNotificationRequest,
  ) {
    return getAppId$(this.store).pipe(
      mergeMap(appId =>
        this.notificationsService.deleteNotification(appId, notificationId),
      ),
      mergeMap(() => ctx.dispatch(new DeleteNotificationSuccess())),
      catchError(error => ctx.dispatch(new DeleteNotificationError(error))),
    );
  }

  @Action(DeleteNotificationSuccess)
  deleteNotificationSuccess(ctx: StateContext<NotificationStateModel>) {
    ctx.patchState({
      isSubmitting: false,
    });
    ctx.dispatch(new CloseNotificationForm());
    ctx.dispatch(new FetchNotificationsRequest(1));
  }

  @Action(DeleteNotificationError)
  deleteNotificationError(ctx: StateContext<NotificationStateModel>) {
    ctx.patchState({
      isSubmitting: false,
    });
  }

  @Action(ScheduleNotificationSuccess)
  scheduleNotificationSuccess(ctx: StateContext<NotificationStateModel>) {
    ctx.patchState({
      isSubmitting: false,
    });
    ctx.dispatch(new FetchNotificationsRequest(1));
  }

  @Action(SubmitDraftSuccess)
  submitDraftSuccess(ctx: StateContext<NotificationStateModel>) {
    ctx.patchState({
      isSubmitting: false,
    });
    ctx.dispatch(new CloseNotificationForm());
    ctx.dispatch(new FetchNotificationsRequest(1));
  }

  @Action(IncrementPage)
  incrementPage(ctx: StateContext<NotificationStateModel>) {
    const currentPage = ctx.getState().currentPage;
    ctx.patchState({
      currentPage: currentPage + 1,
    });
  }

  @Action(DecrementPage)
  decrementPage(ctx: StateContext<NotificationStateModel>) {
    const currentPage = ctx.getState().currentPage;
    ctx.patchState({
      currentPage: currentPage > 1 ? currentPage - 1 : currentPage,
    });
  }

  @Action(ResetPagination)
  resetPagination(ctx: StateContext<NotificationStateModel>) {
    ctx.patchState({
      currentPage: 1,
      lastPage: null,
      totalNumber: null,
      notifications: [],
    });
  }

  @Action(SetPage)
  setPage(ctx: StateContext<NotificationStateModel>, { page }: SetPage) {
    ctx.patchState({
      currentPage: page,
    });
  }

  @Action(FetchTargetedSubscribersRequest)
  fetchTargetedSubscribersRequest(
    ctx: StateContext<NotificationStateModel>,
    { location, lang, platform }: FetchTargetedSubscribersRequest,
  ) {

    return getAppId$(this.store).pipe(
      mergeMap(appId => this.notificationsService.getTargetedSubscribers(appId, location, lang, platform)),
      mergeMap(count => ctx.dispatch(new FetchTargetedSubscribersSuccess(count))),
      catchError(error => ctx.dispatch(new FetchTargetedSubscribersError(error))),
    );
  }

  @Action(FetchTargetedSubscribersSuccess)
  fetchTargetedSubscribersSuccess(
    ctx: StateContext<NotificationStateModel>,
    { count }: FetchTargetedSubscribersSuccess,
  ) {
    ctx.patchState({
      targetedSubscribers: count,
    });
  }

  @Action(FetchTargetedSubscribersError)
  fetchTargetedSubscribersError(
    ctx: StateContext<NotificationStateModel>,
    { error }: FetchTargetedSubscribersError,
  ) {
    ctx.patchState({
      isLoading: false,
    });

    console.error('Error fetching targeted subscribers:', error);
  }
}
