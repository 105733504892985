import { Component, Input, OnInit } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { FetchTargetedSubscribersRequest } from '../../states/notifications.actions';
import {
  NotificationsState,
  TargetedSubscribers,
} from '../../states/notifications.state';

@Component({
  selector: 'rk-notification-targeted-subscribers',
  templateUrl: './notification-targeted-subscribers.component.html',
  styleUrl: './notification-targeted-subscribers.component.scss',
})
export class NotificationTargetedSubscribersComponent implements OnInit {
  @Select(NotificationsState.targetedSubscribers)
  targetedSubscribers$: Observable<TargetedSubscribers>;

  @Input() isIphoneReady: boolean;
  @Input() isAndroidReady: boolean;

  ngOnInit() {
    const platforms = `${this.isAndroidReady ? 'android;' : ''};${
      this.isIphoneReady ? 'iphone' : ''
    }`;
    this.fetchTotalSubscribers(platforms);
  }

  @Dispatch()
  fetchTotalSubscribers(platforms: string) {
    return new FetchTargetedSubscribersRequest('', '', platforms);
  }
}
