import { Notification, NotificationApiResponse } from '../models/notification.model';
import { TargetedSubscribers } from './notifications.state';

const tag = '[Notifications]';

export class FetchNotificationsRequest {
  static readonly type = `${tag} fetch notifications request`;
  constructor(public page: number) {}
}

export class FetchNotificationsSuccess {
  static readonly type = `${tag} fetch notifications success`;
  constructor(public notificationsApiResponse: NotificationApiResponse) {}
}

export class FetchNotificationsError {
  static readonly type = `${tag} fetch notifications error`;
  constructor(public error: Error) {}
}

export class OpenNotificationForm {
  static readonly type = `${tag} open notification form`;
}

export class OpenNotificationFormFromSidebar {
  static readonly type = `${tag} open notification form from sidebar`;
}

export class CloseNotificationForm {
  static readonly type = `${tag} close notification form`;
}

export class SubmitNotificationRequest {
  static readonly type = `${tag} submit notification request`;
  constructor(public notification: Partial<Notification>) {}
}

export class SubmitNotificationSuccess {
  static readonly type = `${tag} submit notification success`;
}

export class SubmitNotificationError {
  static readonly type = `${tag} submit notification error`;
}

export class SubmitNotificationFrequencyError {
  static readonly type = `${tag} submit notification fequency error`;
}

export class SubmitNotificationQuotaError {
  static readonly type = `${tag} submit notification quota error`;
}

export class SubmitDraftSuccess {
  static readonly type = `${tag} submit draft success`;
}

export class OpenEditNotificationForm {
  static readonly type = '[Notifications] Open Edit Notification Form';
  constructor(public notification: Notification) {}
}

export class ResetCurrentNotification {
  static readonly type = '[Notifications] Reset Current Notification';
}

export class UpdateNotificationRequest {
  static readonly type = `${tag} update notification request`;
  constructor(public notification: { notification: Notification; appId: number }) {}
}

export class UpdateNotificationSuccess {
  static readonly type = `${tag} update notification success`;
}

export class UpdateNotificationError {
  static readonly type = `${tag} update notification error`;
  constructor(public error: any) {}
}

export class ScheduleNotificationSuccess {
  static readonly type = `${tag} schedule notification success`;
}

export class DeleteNotificationRequest {
  static readonly type = `${tag} delete notification request`;
  constructor(public notificationId: number) {}
}

export class DeleteNotificationSuccess {
  static readonly type = `${tag} delete notification success`;
}

export class DeleteNotificationError {
  static readonly type = `${tag} delete notification error`;
  constructor(public error: Error) {}
}

export class IncrementPage {
  static readonly type = `${tag} page increment`;
}

export class DecrementPage {
  static readonly type = `${tag} page decrement`;
}

export class ResetPagination {
  static readonly type = `${tag} reset pagination`;
}

export class SetPage {
  static readonly type = `${tag} set page`;
  constructor(public page: number) {}
}

export class FetchTargetedSubscribersRequest {
  static readonly type = `${tag} fetch targeted subscribers request`;
  constructor(
    public location: string,
    public lang: string,
    public platform: string,
  ) {}
}

export class FetchTargetedSubscribersSuccess {
  static readonly type = `${tag} fetch targeted subscribers success`;
  constructor(public count: TargetedSubscribers) {}
}

export class FetchTargetedSubscribersError {
  static readonly type = `${tag} fetch targeted subscribers error`;
  constructor(public error: Error) {}
}
